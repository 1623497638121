import { Route, Routes } from "react-router-dom";

import { Container } from "./common/style";
import { AppContent } from "../AppContent";

import { useAuth } from "../hooks/useAuth";

import { Articles, EditArticle } from "../pages";
import ArticlesArchived from "../pages/Articles/ArticlesArchived";
import { Blogers } from "../pages/Blogers/Blogers";
import CreateArticle from "../pages/Editor/CreateArticle";
import { GroupTherapy } from "../pages/GroupTherapy/GroupTherapy";
import { Workshop } from "../pages/Workshop/Workshop";
import { Orders } from "../pages/Orders/Orders";
import { ShortLinks } from "../pages/ShortLink/ShortLinks";
import { Statistics } from "../pages/Statistics/Statistics";
import { UtmTags } from "../pages/UtmTags/UtmTags";
import Login from "../pages/Login/Login";

const Routers = () => {
	const { isLoggedIn } = useAuth();

	return (
		<>
			{isLoggedIn ? (
				<Container>
					<AppContent>
						<Routes>
							<Route path="/" element={<Articles />} />
							<Route path="/orders" element={<Orders />} />
							<Route path="/workshop" element={<Workshop />} />
							<Route path="/group-therapy" element={<GroupTherapy />} />
							<Route path="/blogers" element={<Blogers />} />
							<Route path="/utmTags" element={<UtmTags />} />
							<Route path="/shortlinks" element={<ShortLinks />} />
							<Route path="/statistics" element={<Statistics />} />
							<Route path="/archived" element={<ArticlesArchived />} />
							<Route path="/create" element={<CreateArticle />} />
							<Route path="/articles/:id" element={<EditArticle />} />
							<Route path="*" element={<Articles />} />
						</Routes>
					</AppContent>
				</Container>
			) : (
				<Container>
					<AppContent hideHeader>
						<Routes>
							<Route path="/" element={<Login />} />
							<Route path="*" element={<Login />} />
						</Routes>
					</AppContent>
				</Container>
			)}
		</>
	);
};

export default Routers;
