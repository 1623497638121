import { api } from "../constants";

export type WorkshopType = {
	date: string;
	name: string;
	emails: string[];
	count: number;
}[];

async function getWorkshop() {
	const loginData = localStorage.getItem("login");

	const response = await fetch(`${api.url}/workshops/get-for-admin`, {
		method: "GET",
		headers: {
			Authorization: `Basic ${window.btoa(loginData || '')}`,
		},
	});
	const data = (await response.json()) as WorkshopType;
	return data;
}

async function sendConferenceLink(body: { linkToConference: string; meetingId: string; passcode: string; emails: string[]; date: string }) {
	const loginData = localStorage.getItem("login");

	const response = await fetch(`${api.url}/workshops/send-conference-link`, {
		method: "POST",
		body: JSON.stringify(body),
		headers: {
			"Content-Type": "application/json",
			Authorization: `Basic ${window.btoa(loginData || '')}`,
		},
	});
	const data = (await response.json()) as string;
	return data;
}

export { getWorkshop, sendConferenceLink };
