import { FC, FormEvent, useEffect, useState } from "react";

import { getGroupTherapy, GroupTherapyType, sendConferenceLink } from "../../api/groupTherapy";
import BigLoader from "../../components/common/BigLoader";
import LoaderForButton from "../../components/common/LoaderForButton";
import InfoMessage from "../../components/InfoMessage";
import { infoMessageTypes } from "../../constants";

const getDate = (value: string) => {
	const date = new Date(value);
	const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
	const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
	const year = date.getFullYear();

	return `${day}-${month}-${year}`;
};

export const GroupTherapy: FC = () => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);
	const [groupTherapyData, setGroupTherapyData] = useState<GroupTherapyType | null>(null);

	const [formLoading, setFormLoading] = useState(false);
	const [formError, setFormError] = useState<string | null>(null);
	const [formSuccess, setFormSuccess] = useState<string | null>(null);
	const [meetingId, setMeetingId] = useState("");
	const [linkToConference, setLinkToConference] = useState("");
	const [conferencePassword, setConferencePassword] = useState("");

	const handleFormSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (groupTherapyData) {
			setFormError(null);
			setFormSuccess(null);
			setFormLoading(true);

			try {
				const response = await sendConferenceLink({
					linkToConference,
					meetingId,
					passcode: conferencePassword,
					emails: groupTherapyData[0].emails,
					date: groupTherapyData[0].date,
				});

				if (response) {
					setFormSuccess(response);
				}
			} catch (err) {
				if (err instanceof Error) {
					setFormError(err.message);
				}
			} finally {
				setFormLoading(false);
			}
		}
	};

	useEffect(() => {
		const getGroupTherapyData = async () => {
			try {
				const data = await getGroupTherapy();
				setGroupTherapyData(data);
			} catch (err) {
				if (err instanceof Error) {
					setError(err.message);
				}
			} finally {
				setLoading(false);
			}
		};

		getGroupTherapyData();
	}, []);

	return (
		<main className="orders">
			{loading ? (
				<BigLoader />
			) : error ? (
				<InfoMessage type={infoMessageTypes.error} message={error} />
			) : groupTherapyData && groupTherapyData.length ? (
				<>
					<>
						<form onSubmit={handleFormSubmit} className="orders__form">
							<fieldset className="shortLink__fieldset shortLink__fieldset--triple">
								<input
									required
									value={linkToConference}
									onChange={(event) => setLinkToConference(event.target.value)}
									className="shortLink__input"
									type="text"
									name="linkToConference"
									id="linkToConference"
									placeholder="Link To Conference"
								/>
								<input
									required
									value={meetingId}
									onChange={(event) => setMeetingId(event.target.value)}
									className="shortLink__input"
									type="text"
									name="meetingId"
									id="meetingId"
									placeholder="Meeting Id"
								/>
								<input
									required
									value={conferencePassword}
									onChange={(event) => setConferencePassword(event.target.value)}
									className="shortLink__input"
									type="text"
									name="conferencePassword"
									id="conferencePassword"
									placeholder="Conference Password"
								/>
							</fieldset>
							<button className="shortLink__btn" type="submit" disabled={formLoading}>
								{formLoading ? <LoaderForButton /> : "Send link"}
							</button>
						</form>
						{formSuccess && <InfoMessage type={infoMessageTypes.success} message={formSuccess} />}
						{formError && <InfoMessage type={infoMessageTypes.error} message={formError} />}
						{groupTherapyData.map((item, index) => (
							<article key={index} className="order">
								<div className="order__header">
									<p className="order__id">{item.name}</p>
									<p className="order__id">{getDate(item.date)}</p>
								</div>
								<div className="order__content">
									<p className="order__info">
										Count:
										<span className="order__info--span">{item.count}</span>
									</p>
									<hr className="order__hr" />
									{item.emails.map((email, emailIndex) => (
										<p className="order__info" key={emailIndex}>
											{emailIndex + 1}) {email}
										</p>
									))}
								</div>
							</article>
						))}
					</>
				</>
			) : (
				<p>There are no orders</p>
			)}
		</main>
	);
};
