import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@stitches/react";

import ArchiveIcon from "../assets/icons/ArchiveIcon";
import { Button, SmallContainer } from "./common/style";

const Header: React.FC = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const checkLocation = location.pathname === "/create" || location.pathname === "/editor";
	const buttonHandler = () => {
		navigate("/create");
	};
	const ordersButton = () => {
		navigate("/orders");
	};
	const workshopButton = () => {
		navigate("/workshop");
	}
	const groupTherapyButton = () => {
		navigate("/group-therapy");
	};
	const blogersButton = () => {
		navigate("/blogers");
	};
	const utmTagsButton = () => {
		navigate("/utmTags");
	};
	const shortLinksButton = () => {
		navigate("/shortlinks");
	};
	const statisticsButton = () => {
		navigate("/statistics");
	};
	const navigationHandler = () => {
		if (location.pathname === "/") {
			navigate("/archived");
		}
		if (
			location.pathname === "/archived" ||
			location.pathname === "/orders" ||
			location.pathname === "/group-therapy" ||
			location.pathname === "/blogers" ||
			location.pathname === "/utmTags" ||
			location.pathname === "/shortlinks" ||
			location.pathname === "/statistics"
		) {
			navigate("/");
		}
	};
	let checkMainLocation;
	if (location.pathname !== "/create" && !location.pathname.includes("/article")) {
		checkMainLocation = true;
	}

	return (
		<StyledHeader>
			<SmallContainer>
				{checkMainLocation && (
					<HeaderContainer>
						{location.pathname === "/" && <div>Articles</div>}
						{location.pathname === "/archived" && <div>Archived Articles</div>}
						{location.pathname === "/orders" && <div>Orders</div>}
						{location.pathname === "/workshop" && <div>Workshop</div>}
						{location.pathname === "/group-therapy" && <div>Group Therapy</div>}
						{location.pathname === "/blogers" && <div>Blogers</div>}
						{location.pathname === "/utmTags" && <div>Utm Tags</div>}
						{location.pathname === "/shortlinks" && <div>Short Links</div>}
						{location.pathname === "/statistics" && <div>Statistics</div>}
						{!checkLocation && (
							<RightButtons>
								<Button onClick={navigationHandler}>
									{location.pathname === "/" ? (
										<>
											<ArchiveIcon /> Archived
										</>
									) : (
										"Back"
									)}
								</Button>
								<Button onClick={ordersButton}>Orders</Button>
								<Button onClick={workshopButton}>Workshop</Button>
								<Button onClick={groupTherapyButton}>Group Therapy</Button>
								<Button onClick={blogersButton}>Blogers</Button>
								<Button onClick={utmTagsButton}>Utm Tags</Button>
								<Button onClick={shortLinksButton}>Short Links</Button>
								<Button onClick={statisticsButton}>Statistics</Button>
								<Button onClick={buttonHandler}>Create</Button>
							</RightButtons>
						)}
					</HeaderContainer>
				)}
			</SmallContainer>
		</StyledHeader>
	);
};

const HeaderContainer = styled("div", {
	display: "flex",
	flexDirection: "column",
	flexWrap: "wrap",
	gap: 8,
});

const StyledHeader = styled("header", {
	marginBottom: 8,
});

const RightButtons = styled("div", {
	display: "flex",
	flexDirection: "row",
	flexWrap: "wrap",
	gap: 8,
});

export default Header;
